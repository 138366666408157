// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/pc/common/img_banner_beginner_pc.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/pc/common/img_banner_beginner_middle.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/sp/common/img_banner_beginner_sp.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".how-to-use-image[data-v-b147380e]{grid-area:how-to-use-image;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-color:#0086cc;background-position-x:-2px;background-position-y:-2px;background-size:344px 626px;border-radius:4px;box-shadow:0 0 16px 0 hsla(0,0%,75.3%,.7);height:622px;transition:box-shadow .4s ease;width:340px}.how-to-use-image[data-v-b147380e]:hover{box-shadow:0 0 16px 0 rgba(0,134,204,.5)}.how-to-use-image[data-v-b147380e]:hover:active{box-shadow:0 0 2px 0 rgba(0,134,204,.5)}@media screen and (max-width:1296px){.how-to-use-image[data-v-b147380e]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:cover;height:160px;width:710px}}@media screen and (max-width:920px){.how-to-use-image[data-v-b147380e]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:contain;width:100%;height:60px;border-radius:0;box-shadow:none}.how-to-use-image[data-v-b147380e]:hover,.how-to-use-image[data-v-b147380e]:hover:active{box-shadow:none}}@media screen and (max-width:550px){.how-to-use-image[data-v-b147380e]{background-size:cover;height:80px}}@media screen and (max-width:414px){.how-to-use-image[data-v-b147380e]{height:60px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
